import Description from './sections/description/description';
import {Footer, NavigationBar} from '../../sections';
import PhotoDisplay from './sections/photo-display/photo-display';
import React from 'react';


const HotelRoom = (props) => {

    const {hotelRoomContent} = props 

    return (
        <>
            <NavigationBar />
            <PhotoDisplay backgroundImage={hotelRoomContent.roomRenderingImagePath} />
            <Description descriptionArray={hotelRoomContent.description} />
            <PhotoDisplay backgroundImage={hotelRoomContent.lineDrawingImagePath} downloadLink={hotelRoomContent.downloadLink} downloadLinkTextRegular={hotelRoomContent.downloadLinkTextRegular} downloadLinkTextUnderlined={hotelRoomContent.downloadLinkTextUnderlined} />
            <Footer />
        </>
    );
}

export default HotelRoom;