import React from 'react';
import './photo-display.css'


const PhotoDisplay = (props) => { 

    const {backgroundImage, downloadLink = null, downloadLinkTextRegular, downloadLinkTextUnderlined} = props;

    return (
        <div 
        className={`hotel-room-photo-display ${!downloadLink ? 'photo-display__intro' : 'photo-display__outro'}`}
        style={{
            backgroundImage: `url("${backgroundImage}")`,
          }}
        >
            {downloadLink && (
                <p className={'hotel-room-photo-display-download--regular'}>
                   {downloadLinkTextRegular} <a className={'hotel-room-photo-display-download--underlined'} href={downloadLink}>{downloadLinkTextUnderlined}</a>
                </p>
            )}
        </div>
    )

}

export default PhotoDisplay;