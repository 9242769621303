import HotelRoom from '../../components/views/hotel-room/hotel-room';
import { hotelRooms as hotelRoomContent } from '../../helpers/content';
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {
    const smartHotel = hotelRoomContent.smartHotel;
    
    return (
    <Layout>
        <SEO title="Smart Hotel Room Layout" />
        <HotelRoom hotelRoomContent={smartHotel} />
    </Layout>
    )
}

export default IndexPage;