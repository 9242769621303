import React from 'react';
import './description.css'

const Description = (props) => {

    const {descriptionArray} = props;

    return (
        <div className={"hotel-room-description"}>
           {descriptionArray.map((text) => (
            <p className={"hotel-room-description--paragraph"}>
                {text}
            </p>
            ))}
        </div>
    )
}

export default Description;